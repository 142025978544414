@font-face {
    font-family: 'Adobe Hebrew';
    src: url('./fonts/AdobeHebrew-Regular.otf') format('truetype');
}

@font-face {
  font-family: "Adobe Hebrew Italic";
  src: url("./fonts/AdobeHebrew-Italic.otf") format("truetype");
}

@media (orientation: portrait){
body{
    margin: 0;
    font-family: 'Adobe Hebrew', serif;
    background-image: url('./img/bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
}

section{
    // height: 100vh;
    display: grid;
    place-content: left center;
    background: rgb(255, 255, 255, 0.4);
    padding: clamp(4rem, 12vw, 5rem) 0;
    backdrop-filter: blur(5px);

    .header-img{
        width: 100vw;
        margin: 20vh 0;
    }

    .half-height{
        margin-top: 50vh;
        height: 50vh;
    }
    
    h1{
        backdrop-filter: blur(0px);
        backdrop-filter: none;
        place-content: center;
        text-align: right;
    }

    h2{
        font-family: 'Adobe Hebrew Italic', serif;
        font-size: clamp(1rem, 4vw, 4rem);
        padding: 0 clamp(4rem, 12vw, 5rem);
        font-weight: 100;
        height: 100vh;
    }

    p{
        font-family: 'Adobe Hebrew Italic', serif;
        font-size: clamp(1rem, 4vw, 4rem);
        padding: 0 clamp(2rem, 6vw, 5rem);
        white-space: pre-line;
        line-height: clamp(2rem, 6vw, 6rem);
    }

    p2{
        display: grid;
        place-content: center;
    }

    .spotify{
        padding: 0 5vw;
        width: 90vw;
        height: 70vh;
    }

    pc{
        margin: 0 auto;
        padding: 12vw 0;
        width: 100vw;
        display: grid;
        place-content: center;
        height: 110vh;
    }

    pcb{
        z-index: 3;
        background-image: url('./img/pc-back.png');
        background-size: cover;
        width: 35vw;
        height: 55vw;
        border-radius: 10px;
        position: absolute;
        margin-left: 43vw;
    }

    pcf{
        z-index: 2;
        background-image: url('./img/pc-front.png');
        background-size: cover;
        width: 35vw;
        height: 55vw;
        margin-left: 25vw;
        border-radius: 10px;
        position: absolute;
    }

    p3 {
        margin-top: 10vh;
        // background-color: black;
    }
}

sbreak{
    height: 15vh;
    display: grid;
    place-content: center;
    background-color: rgba(223, 221, 255, 0.219);
    padding: 0;
    backdrop-filter: blur(5px);

    .header-img{
        width: 50vw;
        // box-shadow: 0px 0px 59px -15px rgba(0,0,0,0.75);
    }

    p{
        font-family: 'Adobe Hebrew Italic', serif;
        font-size: clamp(1rem, 4vw, 8rem);
        padding: 0 clamp(2rem, 6vw, 10rem);
        white-space: pre-line;
    }
}
}

@media (orientation: landscape){
    body{
        margin: 0;
        font-family: 'Adobe Hebrew', serif;
        background-image: url('./img/bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        width: 100vw;
    }
    
    section{
        height: 100vh;
        display: grid;
        place-content: center;
        background: rgb(255, 255, 255, 0.4);
        padding: 0;
        backdrop-filter: blur(5px);
    
        .header-img{
            width: 50vw;
        }
        
        h1{
            backdrop-filter: blur(0px);
            backdrop-filter: none;
            place-content: left center;
            text-align: right;
        }
    
        h2{
            font-family: 'Adobe Hebrew Italic', serif;
            font-size: 2vw;
            padding: 0 12vw;
            font-weight: 100;
        }
    
        p{
            font-family: 'Adobe Hebrew Italic', serif;
            font-size: 2vw;
            padding: 0 6vw;
            white-space: pre-line;
        }
    
        p2{
            display: grid;
            place-content: center;
        }
    
        .spotify{
            padding: 0 5vw;
            width: 90vw;
            height: 70vh;
        }
    
        pc{
            margin: 0 auto;
            padding: clamp(4rem, 12vw, 20rem) 0;
            width: 100vw;
            display: grid;
            place-content: center;

            p3{
                z-index: 5;
                background: rgb(255, 255, 255, 0.4);
            }
        }
    
        pcb{
            z-index: 3;
            background-image: url('./img/pc-back.png');
            background-size: cover;
            width: 14vw;
            height: 22vw;
            border-radius: 10px;
            position: absolute;
            margin-left: 78vw;
        }
    
        pcf{
            z-index: 2;
            background-image: url('./img/pc-front.png');
            background-size: cover;
            width: 14vw;
            height: 22vw;
            margin-left: 73vw;
            border-radius: 10px;
            position: absolute;
        }
    }
    
    sbreak{
        height: 50vh;
        display: grid;
        place-content: center;
        background-color: #B6B4D744;
        padding: 0;
        backdrop-filter: blur(5px);
    
        .header-img{
            width: 25vw;
        }
    
        p{
            font-family: 'Adobe Hebrew Italic', serif;
            font-size: clamp(1rem, 4vw, 8rem);
            padding: 0 clamp(2rem, 6vw, 10rem);
            white-space: pre-line;
        }
    }
    }