@font-face {
  font-family: "Adobe Hebrew";
  src: url("./fonts/AdobeHebrew-Regular.otf") format("truetype");
}
@font-face {
  font-family: "Adobe Hebrew Italic";
  src: url("./fonts/AdobeHebrew-Italic.otf") format("truetype");
}
@media (orientation: portrait) {
  body {
    margin: 0;
    font-family: "Adobe Hebrew", serif;
    background-image: url("./img/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
  }
  section {
    display: grid;
    place-content: left center;
    background: rgba(255, 255, 255, 0.4);
    padding: clamp(4rem, 12vw, 5rem) 0;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
  }
  section .header-img {
    width: 100vw;
    margin: 20vh 0;
  }
  section .half-height {
    margin-top: 50vh;
    height: 50vh;
  }
  section h1 {
    -webkit-backdrop-filter: blur(0px);
            backdrop-filter: blur(0px);
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
    place-content: center;
    text-align: right;
  }
  section h2 {
    font-family: "Adobe Hebrew Italic", serif;
    font-size: clamp(1rem, 4vw, 4rem);
    padding: 0 clamp(4rem, 12vw, 5rem);
    font-weight: 100;
    height: 100vh;
  }
  section p {
    font-family: "Adobe Hebrew Italic", serif;
    font-size: clamp(1rem, 4vw, 4rem);
    padding: 0 clamp(2rem, 6vw, 5rem);
    white-space: pre-line;
    line-height: clamp(2rem, 6vw, 6rem);
  }
  section p2 {
    display: grid;
    place-content: center;
  }
  section .spotify {
    padding: 0 5vw;
    width: 90vw;
    height: 70vh;
  }
  section pc {
    margin: 0 auto;
    padding: 12vw 0;
    width: 100vw;
    display: grid;
    place-content: center;
    height: 110vh;
  }
  section pcb {
    z-index: 3;
    background-image: url("./img/pc-back.png");
    background-size: cover;
    width: 35vw;
    height: 55vw;
    border-radius: 10px;
    position: absolute;
    margin-left: 43vw;
  }
  section pcf {
    z-index: 2;
    background-image: url("./img/pc-front.png");
    background-size: cover;
    width: 35vw;
    height: 55vw;
    margin-left: 25vw;
    border-radius: 10px;
    position: absolute;
  }
  section p3 {
    margin-top: 10vh;
  }
  sbreak {
    height: 15vh;
    display: grid;
    place-content: center;
    background-color: rgba(223, 221, 255, 0.219);
    padding: 0;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
  }
  sbreak .header-img {
    width: 50vw;
  }
  sbreak p {
    font-family: "Adobe Hebrew Italic", serif;
    font-size: clamp(1rem, 4vw, 8rem);
    padding: 0 clamp(2rem, 6vw, 10rem);
    white-space: pre-line;
  }
}
@media (orientation: landscape) {
  body {
    margin: 0;
    font-family: "Adobe Hebrew", serif;
    background-image: url("./img/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
  }
  section {
    height: 100vh;
    display: grid;
    place-content: center;
    background: rgba(255, 255, 255, 0.4);
    padding: 0;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
  }
  section .header-img {
    width: 50vw;
  }
  section h1 {
    -webkit-backdrop-filter: blur(0px);
            backdrop-filter: blur(0px);
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
    place-content: left center;
    text-align: right;
  }
  section h2 {
    font-family: "Adobe Hebrew Italic", serif;
    font-size: 2vw;
    padding: 0 12vw;
    font-weight: 100;
  }
  section p {
    font-family: "Adobe Hebrew Italic", serif;
    font-size: 2vw;
    padding: 0 6vw;
    white-space: pre-line;
  }
  section p2 {
    display: grid;
    place-content: center;
  }
  section .spotify {
    padding: 0 5vw;
    width: 90vw;
    height: 70vh;
  }
  section pc {
    margin: 0 auto;
    padding: clamp(4rem, 12vw, 20rem) 0;
    width: 100vw;
    display: grid;
    place-content: center;
  }
  section pc p3 {
    z-index: 5;
    background: rgba(255, 255, 255, 0.4);
  }
  section pcb {
    z-index: 3;
    background-image: url("./img/pc-back.png");
    background-size: cover;
    width: 14vw;
    height: 22vw;
    border-radius: 10px;
    position: absolute;
    margin-left: 78vw;
  }
  section pcf {
    z-index: 2;
    background-image: url("./img/pc-front.png");
    background-size: cover;
    width: 14vw;
    height: 22vw;
    margin-left: 73vw;
    border-radius: 10px;
    position: absolute;
  }
  sbreak {
    height: 50vh;
    display: grid;
    place-content: center;
    background-color: rgba(182, 180, 215, 0.2666666667);
    padding: 0;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
  }
  sbreak .header-img {
    width: 25vw;
  }
  sbreak p {
    font-family: "Adobe Hebrew Italic", serif;
    font-size: clamp(1rem, 4vw, 8rem);
    padding: 0 clamp(2rem, 6vw, 10rem);
    white-space: pre-line;
  }
}/*# sourceMappingURL=App.css.map */